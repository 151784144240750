import React, { useEffect, useState } from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContentContainer from "../components/ContentContainer";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "gatsby";

const Contact = ({ user }) => {

	const [contactData, setContactData] = useState({
		email: user.email ? user.email : "",
		name: (user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}` : "",
		category: "",
		subject: "",
		message: "",

		status: "",
		policy: false,
		valid: false,
		touched: false,
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		setContactData({ ...contactData, touched: true });

		if (contactData.valid) {
			setContactData({ ...contactData, status: "SENT" });
			try {
				await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/contact`, {
					email: contactData.email,
					name: contactData.name,
					category: contactData.category,
					subject: contactData.subject,
					message: contactData.message,
					policy: contactData.policy,
				});
				setContactData({ ...contactData, email: "", name: "", category: "", subject: "", message: "", policy: false, status: "SUCCESS" });
			} catch (err) {
				console.error(err);
				setContactData({ ...contactData, status: "ERROR" });
			}
		}
	};

	useEffect(() => {
		setContactData({ ...contactData, valid: !!contactData.name && !!contactData.category && !!contactData.subject && !!contactData.message && contactData.policy })
	}, [contactData.email, contactData.name, contactData.category, contactData.subject, contactData.message, contactData.policy]);

	return (
		<Layout>
			<SEO title="Kontakt"/>
			<ContentContainer title="Kontakt" half>
				<div>
					<div className="col-sm-12 col-md-7 d-block mx-auto">
						<p className="mt-3 mb-4 color-subtext">Bei Fragen, Problemen oder einfach nur Feedback kannst du uns gerne eine Nachricht zukommen lassen.</p>
					</div>
					<Form onSubmit={ e => handleSubmit(e) } className="col-sm-12 col-md-7 d-block mx-auto">
						<FormGroup>
							<Label for="email">Email</Label>
							<Input type="email" name="email" id="email" placeholder="max@mustermann.de" className="round"
									value={ contactData.email }
									invalid={ !contactData.email }
									onChange={ e => setContactData({ ...contactData, email: e.target.value }) }/>
						</FormGroup>
						<FormGroup>
							<Label for="name">Name</Label>
							<Input type="text" name="name" id="name" placeholder="Max Mustermann" className="round"
									value={ contactData.name }
									invalid={ !contactData.name }
									onChange={ e => setContactData({ ...contactData, name: e.target.value }) }/>
						</FormGroup>
						<FormGroup>
							<Label for="category">Kategorie</Label>
							<Input type="select" name="category" id="category" className="round"
									value={ contactData.category }
									onChange={ e => setContactData({ ...contactData, category: e.target.value }) }
									invalid={ !contactData.category }>
								<option value="">Kategorie wählen</option>
								<option>Support</option>
								{/* <option>Sales</option>
								<option>Legal Inquiries</option> */}
								<option>Sonstiges</option>
							</Input>
						</FormGroup>
						<FormGroup>
							<Label for="name">Betreff</Label>
							<Input type="text" name="subject" id="subject" placeholder="Dein Betreff" className="round"
									value={ contactData.subject }
									invalid={ !contactData.subject }
									onChange={ e => setContactData({ ...contactData, subject: e.target.value }) }/>
						</FormGroup>
						<FormGroup>
							<Label for="message">Nachricht</Label>
							<Input type="textarea" name="text" id="message" placeholder="Deine Nachricht..."
								value={ contactData.message }
								invalid={ !contactData.message }
								onChange={ e => setContactData({ ...contactData, message: e.target.value })} />
						</FormGroup>
						<FormGroup>
							<Label for="policy"><Input type="checkbox" name="policy" id="policy"
								value={ contactData.policy }
								invalid={ !contactData.policy }
								onChange={ e => setContactData({ ...contactData, policy: e.target.checked })} />
								Ja, ich habe die <Link className="underlined" to="/privacy-policy">Datenschutzerklärung</Link> zur Kenntnis genommen und bin damit einverstanden, dass die von mir angegebenen Daten elektronisch erhoben und gespeichert werden. Meine Daten werden dabei nur streng zweckgebunden zur Bearbeitung und Beantwortung meiner Anfrage benutzt. Mit dem Absenden des Kontaktformulars erkläre ich mich mit der Verarbeitung einverstanden.</Label>
						</FormGroup>
						{ contactData.status === "ERROR" &&
						<p className="feedback-error">{ contactData.status }</p> }
						{ contactData.status === "SUCCESS" &&
						<p className="feedback-success">Deine Nachricht wurde verschickt.</p> }
						<Button color="primary" block className="round mt-4 py-2"
								disabled={ !contactData.valid || contactData.status === "SENT" || !contactData.policy }>Kontakt aufnehmen</Button>
					</Form>
				</div>
			</ContentContainer>
		</Layout>
	);
};

export default connect(state => ({
	user: state.user.user,
}))(Contact);
